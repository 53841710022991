/**
 * Created by stefania on 7/13/17.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from 'rxjs';
import {Page} from "../utils/entities/adminTool/page";
import {PageHelpContent} from "../utils/entities/adminTool/page-help-content";
import {Portal} from "../utils/entities/adminTool/portal";
import {Entity} from "../utils/entities/adminTool/entity";
import {DivId} from "../utils/entities/adminTool/divId";
import {DivHelpContent} from "../utils/entities/adminTool/div-help-content";
import {StatisticsDisplay, StatisticsSummary} from '../connect/statistics/statisticsEntities';
import {CustomOptions} from './servicesUtils/customOptions.class';
import {catchError, map} from "rxjs/operators";
import {properties} from "../../../environments/environment";
import {Menu, MenuItem, MenuItemExtended} from '../sharedComponents/menu';

@Injectable()
export class HelpContentService {

  constructor(private http:HttpClient) {
  }

  static removeNulls(obj){
    var isArray = obj instanceof Array;
    for (var k in obj){
      if (obj[k]===null || obj[k]==='') isArray ? obj.splice(k,1) : delete obj[k];
      else if (typeof obj[k]=="object") HelpContentService.removeNulls(obj[k]);
    }
  }

  getEntities(helpContentUrl:string) {
    return this.http.get<Array<Entity>>(helpContentUrl + 'entity')
      .pipe(catchError(this.handleError));
  }

  saveEntity(entity: Entity, helpContentUrl:string) {
    HelpContentService.removeNulls(entity);

    return this.http.post<Entity>(helpContentUrl + 'entity/save', JSON.stringify(entity), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  updateEntity(entity: Entity, helpContentUrl:string) {
    HelpContentService.removeNulls(entity);

    return this.http.post<Entity>(helpContentUrl + 'entity/update', JSON.stringify(entity), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deleteEntities(ids : string[], helpContentUrl:string) {
    return this.http.post(helpContentUrl + 'entity/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // toggleEntity(selectedCommunityId: string, id : string,status : boolean) {
  //     let headers = new Headers({'Content-Type': 'application/json'});
  //     let options = new RequestOptions({headers: headers});
  //
  //     return this.http.post(helpContentUrl + 'community/'+selectedCommunityId+'/entity/toggle?status='+ status.toString()+'&entityId='+id.toString(), options)
  //         .catch(this.handleError);
  // }

  getCommunityEntities(pid: string, helpContentUrl:string) {
    return this.http.get<Array<Entity>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/entities')
      .pipe(catchError(this.handleError));
  }

  toggleEntities(pid: string, ids : string[],status : boolean, helpContentUrl:string) {

    return this.http.post(helpContentUrl + properties.adminToolsPortalType + '/'+pid+ '/entity/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // unused
  toggleEntityOfPage(pageId: string, entityId : string,status : boolean, helpContentUrl:string) {
    return this.http.post(helpContentUrl + 'page/'+pageId+'/entity/toggle?status='+ status.toString()+'&entityId='+entityId.toString(),
      CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // getDivIdsFull(page_id: string, helpContentUrl:string, pid: string = null) {
  //   let parameters: string = "";
  //   if(page_id || pid) {
  //     parameters = "?";
  //     if(page_id) {
  //       parameters += "&page="+page_id;
  //     }
  //     if(pid) {
  //       parameters += "&portal="+pid;
  //     }
  //   }
  //
  //   return this.http.get<Array<DivId>>(helpContentUrl + 'div/full'+parameters)
  //       //.map(res => <Array<DivId>> res.json())
  //       .pipe(catchError(this.handleError));
  // }

  // Replacing getDivIdsFull
  getAllDivIdsFull(helpContentUrl:string) {
    return this.http.get<Array<DivId>>(helpContentUrl + 'div/full')
    //.map(res => <Array<DivId>> res.json())
      .pipe(catchError(this.handleError));
  }

  getDivIdsFullByPortal(page_id: string, helpContentUrl:string, pid: string) {
    let parameters: string = page_id ? "?&page="+page_id : "";

    return this.http.get<Array<DivId>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid + '/div/full'+parameters)
      .pipe(catchError(this.handleError));
  }
  // End of replacing getDivIdsFull

  // unused
  getDivId(divId: string, helpContentUrl:string) {
    return this.http.get<DivId>(helpContentUrl + 'div/'+divId)
      .pipe(catchError(this.handleError));
  }

  getDivIdFull(divId: string, helpContentUrl:string, pid: string) {
    return this.http.get<DivId>(helpContentUrl + properties.adminToolsPortalType + "/" + pid + '/div/'+divId+"/full")
      .pipe(catchError(this.handleError));
  }

  updateDivId(divId: DivId, helpContentUrl:string) {
    HelpContentService.removeNulls(divId);

    return this.http.post<DivId>(helpContentUrl + 'div/update', JSON.stringify(divId), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  saveDivId(divId: DivId, helpContentUrl:string) {
    HelpContentService.removeNulls(divId);

    return this.http.post<DivId>(helpContentUrl + 'div/save', JSON.stringify(divId), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deleteDivIds(ids : string[], helpContentUrl:string) {
    return this.http.post(helpContentUrl + 'div/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  getPageIdsFromDivIds(pid: string, helpContentUrl:string) {
    return this.http.get<Array<string>>(helpContentUrl + properties.adminToolsPortalType + "/" + pid + '/div/pages')
      .pipe(catchError(this.handleError));
  }

  getCommunityDivHelpContents(pid: string, helpContentUrl:string, pageId:string = null) {
    return this.http.get<Array<DivHelpContent>>(helpContentUrl + properties.adminToolsPortalType + "/" + pid + (pageId ? '/' + pageId : '') + '/divhelpcontent')
      .pipe(catchError(this.handleError));
  }

  getDivHelpContent(id : string, helpContentUrl:string, pid: string) {
    return this.http.get<DivHelpContent>(helpContentUrl + properties.adminToolsPortalType + "/" + pid + '/divhelpcontent/' + id)
      .pipe(catchError(this.handleError));
  }

  insertOrUpdateDivHelpContent(divHelpContent: DivHelpContent, helpContentUrl:string, pid: string) {
    HelpContentService.removeNulls(divHelpContent);
    return this.http.post<DivHelpContent>(helpContentUrl + properties.adminToolsPortalType + "/" + pid + '/divhelpcontent/' + (divHelpContent._id ? 'update' : 'save'),
      JSON.stringify(divHelpContent), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }


  deleteDivHelpContents(ids : string[], helpContentUrl:string, pid: string) {
    return this.http.post(helpContentUrl + properties.adminToolsPortalType + "/" + pid + '/divhelpcontent/delete',
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  toggleDivHelpContents(ids : string[],status : boolean, helpContentUrl:string, pid: string) {

    return this.http.post(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/divhelpcontent/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  // unused
  getPageHelpContents(helpContentUrl:string) {
    return this.http.get<Array<PageHelpContent>>(helpContentUrl + 'pagehelpcontent')
      .pipe(catchError(this.handleError));
  }


  getCommunityPageHelpContents(pid: string, helpContentUrl:string, pageId:string = null) {
    return this.http.get<Array<PageHelpContent>>(helpContentUrl + properties.adminToolsPortalType + '/' + pid + (pageId ? '/' + pageId : '') + '/pagehelpcontent')
      .pipe(catchError(this.handleError));
  }
  countCommunityPageHelpContents(pid: string, helpContentUrl:string ,classContents:boolean=false) {
    return this.http.get<Array<PageHelpContent>>(helpContentUrl + properties.adminToolsPortalType + '/' + pid  + (classContents?"/divhelpcontent":"/pagehelpcontent")+'/page/count')
      .pipe(catchError(this.handleError));
  }
  getPageHelpContent(id : string, helpContentUrl:string, pid: string) {
    return this.http.get<PageHelpContent>(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/' + id)
    //.map(res => <PageHelpContent> res.json())
      .pipe(catchError(this.handleError));
  }

  savePageHelpContent(pageHelpContent: PageHelpContent, helpContentUrl:string, pid: string) {
    HelpContentService.removeNulls(pageHelpContent);

    return this.http.post<PageHelpContent>(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/' + (pageHelpContent._id ? 'update' : 'save'),
      JSON.stringify(pageHelpContent), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  updatePageHelpContent(pageHelpContent: PageHelpContent, helpContentUrl:string, pid: string) {
    HelpContentService.removeNulls(pageHelpContent);

    return this.http.post<PageHelpContent>(helpContentUrl + properties.adminToolsPortalType + '/' + pid +'/pagehelpcontent/update',
      JSON.stringify(pageHelpContent), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deletePageHelpContents(ids : string[], helpContentUrl:string, pid: string) {
    return this.http.post(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/delete',
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  togglePageHelpContents(ids : string[],status : boolean, helpContentUrl:string, pid: string) {
    return this.http.post(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/pagehelpcontent/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  getCommunityPagesWithDivId(pid: string, helpContentUrl:string) {
    return this.http.get<Array<Page>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/pages?div=true')
      .pipe(catchError(this.handleError));
  }

  // getCommunityPages(pid: string, params: string, helpContentUrl:string) {
  //   return this.http.get<Array<Page>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/pages'+params, CustomOptions.getAuthOptions())
  //   //.map(res => <Array<Page>> res.json())
  //     .pipe(catchError(this.handleError));
  // }

  // Replacing getCommunityPages
  getCommunityPagesByType(pid: string, type: string, helpContentUrl:string) {
    return this.http.get<Array<Page>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/pages'
      + (type ? '?page_type='+type : ''))
      .pipe(catchError(this.handleError));
  }
  // End of replacing getCommunityPages

  // Replacing part of getPages (now getAllPages)
  getCommunityPagesWithPositions(pid: string, helpContentUrl:string) {
    return this.http.get<Array<Page>>(helpContentUrl + properties.adminToolsPortalType + '/'+pid+'/pages?with_positions=true')
      .pipe(catchError(this.handleError));
  }
  // End of replacing part of getPages (now getAllPages)
//TODO double check with konstantina - there is no param that we are asking the community pages. without pid we get all portalTypes
  getAllPages(helpContentUrl:string, pid:string = null) {//with_positions:boolean=null) {
    // let parameters: string = "";
    // if(pid || with_positions == true || with_positions == false) {
    //   parameters = "?";
    //   if(pid) {
    //     parameters += "&pid="+pid;
    //   }
    //   if(with_positions == true || with_positions == false) {
    //     parameters += "&with_positions="+with_positions;
    //   }
    // }
    return this.http.get<Array<Page>>(helpContentUrl + 'page?' + (pid?"pid="+pid:""))
    //.map(res => <Array<Page>> res.json())
      .pipe(catchError(this.handleError));
  }

  getAllPagesFull(helpContentUrl:string) {
    return this.http.get<Array<Page>>(helpContentUrl + 'page/full')//+(pid?("?pid="+pid):""))
    //.map(res => <Array<Page>> res.json())
      .pipe(catchError(this.handleError));
  }

  getPageByPortal(pageId:string, helpContentUrl:string, pid: string) {
    return this.http.get<Page>(helpContentUrl + properties.adminToolsPortalType + '/' + pid + '/page/'+pageId)
      .pipe(catchError(this.handleError));
  }
  getPageById(pageId:string, helpContentUrl:string) {
    return this.http.get<Page>(helpContentUrl +  'page/' + pageId)
      .pipe(catchError(this.handleError));
  }
  getCommunityPageByRoute(route:string, helpContentUrl:string, pid: string) {
    return this.http.get<Page>(helpContentUrl + properties.adminToolsPortalType +'/' + pid + '/page/?page_route='+route)
      .pipe(catchError(this.handleError));
  }

  savePage(page: Page, helpContentUrl:string) {
    HelpContentService.removeNulls(page);

    return this.http.post<Page>(helpContentUrl + 'page/save', JSON.stringify(page), CustomOptions.getAuthOptionsWithBody())
    //.map(res => <Page> res.json())
      .pipe(catchError(this.handleError));
  }

  updatePage(page: Page, helpContentUrl:string) {

    HelpContentService.removeNulls(page);

    return this.http.post<Page>(helpContentUrl + 'page/update', JSON.stringify(page), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  togglePages(selectedPortalPid: string, ids : string[],status : boolean, helpContentUrl:string) {

    return this.http.post(helpContentUrl + properties.adminToolsPortalType + '/'+selectedPortalPid+'/page/toggle?status='+ status.toString(),
      JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  deletePages(ids : string[], helpContentUrl:string) {

    return this.http.post(helpContentUrl + 'page/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

	// Menu Items

	getMenuItems(portalPid: string) {
		return this.http.get<Menu>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/full")
			.pipe(catchError(this.handleError));
	}

	saveMenuItem(menuItem: MenuItemExtended, portalPid: string) {
		HelpContentService.removeNulls(menuItem);

		return this.http.post<MenuItemExtended>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/save", JSON.stringify(menuItem), CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	updateMenuItem(menuItem: MenuItemExtended, portalPid: string) {
		HelpContentService.removeNulls(menuItem);
		return this.http.post<MenuItemExtended>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/update", JSON.stringify(menuItem), CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	deleteMenuItem(menuItemId: string, portalPid: string) {
		return this.http.post<MenuItem>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/delete", menuItemId, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	reorderMenuItems(menuItems, portalPid: string) {
		return this.http.post<Array<MenuItem>>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/reorder", menuItems, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

	toggleMenu(status: boolean, isFeatured: boolean, portalPid: string) {
		return this.http.post<Menu>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/toggle?status=" + status + "&featured=" + isFeatured, {}, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}

  alignMenu(alignment: string, portalPid: string) {
		return this.http.post<Menu>(properties.adminToolsAPIURL + properties.adminToolsPortalType + "/" + portalPid + "/menu/align?alignment=" + alignment, {}, CustomOptions.getAuthOptionsWithBody())
			.pipe(catchError(this.handleError));
	}
	

  // unused
  getCommunities( helpContentUrl:string) {
    return this.http.get<Array<Portal>>(helpContentUrl + properties.adminToolsPortalType)
      .pipe(catchError(this.handleError));
  }

  getCommunity(pid: string, helpContentUrl:string) {
    return this.http.get<Portal>(helpContentUrl + properties.adminToolsPortalType + '/'+pid)
      .pipe(catchError(this.handleError));
  }

  getCommunitiesFull( helpContentUrl:string) {
    return this.http.get<Array<Portal>>(helpContentUrl + properties.adminToolsPortalType + '/full')
      .pipe(catchError(this.handleError));
  }

  getPortalsFull( helpContentUrl:string) {
    return this.http.get<Array<Portal>>(helpContentUrl +  'portal/full')
    //.map(res => <Array<Portal>> res.json())
      .pipe(catchError(this.handleError));
  }
  getCommunityFull(portal_pid: string, helpContentUrl:string) {
    return this.http.get<Portal>(helpContentUrl + properties.adminToolsPortalType + '/'+portal_pid+'/full')
      .pipe(catchError(this.handleError));
  }

  saveCommunity(portal: Portal, helpContentUrl:string) {
    // let headers = new Headers({'Content-Type': 'application/json'});
    // let options = new RequestOptions({headers: headers});

    HelpContentService.removeNulls(portal);

    return this.http.post<Portal>(helpContentUrl + portal.type + '/save', JSON.stringify(portal), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  updateCommunity(portal: Portal, helpContentUrl:string) {
    // let headers = new Headers({'Content-Type': 'application/json'});
    // let options = new RequestOptions({headers: headers});

    HelpContentService.removeNulls(portal);

    return this.http.post<Portal>(helpContentUrl + portal.type + '/update', JSON.stringify(portal),  CustomOptions.getAuthOptionsWithBody())
    //.map(res => <Portal> res.json())
      .pipe(catchError(this.handleError));
  }

  deleteCommunities(ids : string[], helpContentUrl:string) {
    // let headers = new Headers({'Content-Type': 'application/json'});
    // let options = new RequestOptions({headers: headers});

    return this.http.post(helpContentUrl + properties.adminToolsPortalType + '/delete',JSON.stringify(ids), CustomOptions.getAuthOptionsWithBody())
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    // in a real world app, we may send the error to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error);
    return throwError(error.error || 'Server error');
  }

  // getDataProviders() {
  //     return this.http.get('https://beta.services.openaire.eu/search/v2/api/datasources?format=json').map(res => <any> res.json()).map(res => res.results).do(res => {console.log(res)}).catch(this.handleError);
  // }

  getCommunityStatistics(apiUrl: string, portalId: string): Observable<StatisticsSummary> {
    const url = `${apiUrl}communities/${portalId}`;
    //console.log(`getting statistics summary from: ${url}`);
    return this.http.get(url)
    //.map(res => <any>res.json())
      .pipe(map(res => res['statistics']));
  }

  getCommunityAdminStatisticsChoices(apiUrl: string, portalId: string): Observable<StatisticsDisplay> {
    const url = `${apiUrl}statistics/${portalId}`;
    //console.log(`getting admin choices for statistics from: ${url}`);
    return this.http.get<StatisticsDisplay>(url)
    //.map(stats => <StatisticsDisplay>stats.json())
      .pipe(catchError(this.handleError));
  }

  postCommunityAdminStatisticsChoices(apiUrl: string,
                                      portalId: string,
                                      entity: string,
                                      chartsOrNumbers: string,
                                      title: string,
                                      status: boolean,
                                      monitor: boolean): Observable<any> {
    const url = `${apiUrl}statistics/${portalId}/${entity}/${chartsOrNumbers}?status=${status.toString()}&monitor=${monitor.toString()}`;
    //console.log(`getting admin choices for statistics from: ${url}`);

    return this.http.post(url, title, CustomOptions.getAuthOptionsWithBody())
    //.map(stats => <any>stats.json())
      .pipe(catchError(this.handleError));
  }

  statisticsIsActiveToggle(apiURL: string, id: string): Observable<boolean> {
    const url = apiURL + '/statistics/' + encodeURIComponent(id) + '/toggle';
    return this.http.post<boolean>(url, {}, CustomOptions.getAuthOptionsWithBody()).pipe(catchError(this.handleError));
  }
}
