import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {from, Observable, Subscription} from 'rxjs';
import {EnvProperties} from "../properties/env-properties";
import {Portal} from "../entities/adminTool/portal";
import {Page} from "../entities/adminTool/page";
import {AdvancedAsyncSubject} from "../AdvancedAsyncSubject";
import {properties} from "../../../../environments/environment";

@Injectable({providedIn: 'root'})
export class ConfigurationService {
  private portal: AdvancedAsyncSubject<Portal> = new AdvancedAsyncSubject<Portal>();
  private sub: Subscription = null;
  private promise: Promise<void> = null;
  
  constructor(private http: HttpClient) {
  }
  
  ngOnDestroy() {
    this.clearSubscriptions();
  }
  
  clearSubscriptions() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public initStaticPortal(portal: Portal) {
    this.promise = new Promise<void>(resolve => {
      this.portal.next(portal);
      resolve();
    })
  }
  
  public initPortal(properties: EnvProperties, pid: string) {
    if (pid == null || this.portal?.getValue()?.static) return;
    let url = properties.adminToolsAPIURL + "/" + properties.adminToolsPortalType + "/" + pid + "/full";
    this.promise = new Promise<void>(resolve => {
      this.sub = this.http.get<Portal>(/*(properties.useLongCache) ? (properties.cacheUrl + encodeURIComponent(url) + (properties.forceCacheReload?'&forceReload=true':'')) :*/ url).subscribe(
          (portal: Portal) => {
            this.portal.next(portal);
            resolve();
          },
          error => {
            this.portal.next(null);
            resolve();
          });
    });
  }
  
  public get portalAsObservable(): Observable<Portal> {
    return this.portal.asObservable();
  }
  
  async isPageEnabledAsync(properties: EnvProperties, portal: string, page_route: string) {
    if (!this.promise || (this.portal && this.portal.getValue() && portal !== this.portal.getValue().pid)) {
      this.initPortal(properties, portal);
    }
    
    await this.promise;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    return this.filtering(page_route);
  }
  
  isPageEnabled(properties: EnvProperties, portal: string, router: string): Observable<boolean> {
    let page_route: string = router.split('?')[0].substring(1);
    return from(this.isPageEnabledAsync(properties, portal, page_route));
  }
  
  filtering(page_route: string) {
    let community: Portal = this.portal.getValue();
    let pages: Page[] = <Page[]>community.pages;
    if (pages) {
      let page = pages.find((page: Page) => page.route == page_route);
      return page && page.isEnabled;
    } else {
      return false;
    }
  }
}
