import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";


import {EnvProperties} from '../properties/env-properties';
import {of} from "rxjs";
import {properties} from "../../../../environments/environment";

@Injectable()
export class HelperService {
  constructor(private http: HttpClient) {
  }

  /**
   * @deprecated
   */
  getHelper(router: string, position: string, before: boolean, div: string, properties: EnvProperties, communityId: string): any {
    //console.info("get router helpText for : "+router+" - position="+position+" - before="+before + " - div="+div);

    let url = properties.adminToolsAPIURL;
    if (div) {
      url += '/divhelpcontent?active=true&community=' + communityId + '&page=' + router + '&div=' + div;
    } else {
      url += '/pagehelpcontent?active=true&community=' + communityId + '&page=' + router + '&position=' + position;
      if (before) {
        url += '&before=' + before;
      }
    }

    return this.http.get(/*(properties.useLongCache)? (properties.cacheUrl+encodeURIComponent(url)+ (properties.forceCacheReload?'&forceReload=true':'')):*/ url);
    //.map(res => <any> res.json());

  }

  getPageHelpContents(properties: EnvProperties, portal: string, router: string, portalType = properties.adminToolsPortalType): any {
    if (!portal) {
      portal = properties.adminToolsCommunity;
    }
    if (!portal) {
      portal = 'openaire';
    }
    if (typeof properties.useHelpTexts == "undefined" || properties.useHelpTexts) {
      let page_route: string = router.split('?')[0].substring(0);
      let url = properties.adminToolsAPIURL;
      url += '/' + portalType + '/' + portal + '/pagehelpcontent/grouped?active=true&page=' +
          ((page_route.indexOf("/" + portal + "/") != -1) ? ("/" + page_route.split("/" + portal + "/")[1]) : page_route);
      return this.http.get(/*(properties.useLongCache) ? (properties.cacheUrl + encodeURIComponent(url)+ (properties.forceCacheReload?'&forceReload=true':'')) :*/ url);
    } else {
      return of(null);
    }
  }

  getDivHelpContents(properties: EnvProperties, communityId: string, router: string): any {
    if (!communityId) {
      communityId = properties.adminToolsCommunity;
    }
    if (!communityId) {
      communityId = 'openaire';
    }
    if (typeof properties.useHelpTexts == "undefined" || properties.useHelpTexts) {
      let page_route: string = router.split('?')[0].substring(0);
      let url = properties.adminToolsAPIURL;
      url += '/' + properties.adminToolsPortalType + '/' + communityId + '/divhelpcontent/grouped?active=true&page=' +
          ((page_route.indexOf("/" + communityId + "/") != -1) ? ("/" + page_route.split("/" + communityId + "/")[1]) : page_route);
      return this.http.get((properties.useLongCache) ? (properties.cacheUrl + encodeURIComponent(url) + (properties.forceCacheReload ? '&forceReload=true' : '')) : url);
    } else {
      return of(null);
    }
  }

}
